<template>
  <div>
    <Vue3DatePicker 
		v-model="selectedDate"
		:highlighted="highlightedDates"
		:format="format"
		@update:model-value="emitSelectedDate"
		:input-class-name="'datepicker-input primary-color filter-text'"
		:clearable="false"
		:hide-navigation="['time']"
		hide-input-icon
		auto-apply
    />
  </div>
</template>

<script>
import Vue3DatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';

export default {
	name: 'DatepickerComponent',
	components: {
		Vue3DatePicker
	},
	props: {
		value: Date // Optional: if you want to use v-model directly
	},
	data() {
		return {
			selectedDate: this.value || new Date(), // Initialize with provided value or current date
			format: 'dd/MM/yyyy',
			highlightedDates: {
				dates: [new Date()],
			}
		};
	},
	watch: {
		value(newValue) {
			this.selectedDate = newValue;
		}
	},
	methods: {
		emitSelectedDate(date) {
			this.selectedDate = date;
			this.$emit('update:model-value', date); // Emit event to parent
		},
	}
};
</script>

<style>
.datepicker-input {
	width: 80px;
	padding: 0;
	border: none;
	border-radius: unset;
	line-height: unset;
	font-size: 14px;
	font-weight: 600;
}
</style>
