<template>
	<div class="score-data">
		<div class="row align-items-center filters">
			<div class="col-md-6">
				<div class="d-flex flex-row justify-content-center justify-content-md-start gap-1 filter-type">
					<a href="#" class="text-center type all active" id="all">{{ $tr('all') }}</a>
					<a href="#" class="text-center type hot-games d-none" id="hot-games">{{ $tr('hot_games') }}</a>
					<a href="#" class="text-center type leagues d-none" id="leagues">{{ $tr('leagues') }}</a>
					<a href="#" class="text-center type odds d-none" id="odds">{{ $tr('odds') }}</a>
				</div>
			</div>
			<div class="col-md-6">
				<div class="d-flex justify-content-end filter-date">
					<div class="d-flex flex-row align-items-center justify-content-between gap-2">
						<i class="fas fa-chevron-left tertiary-color fw-bold cursor-pointer dp__pointer" id="prev" @click="updateSelectedDate('prev')"></i>
						<div class="d-flex flex-row align-items-center gap-2">
							<i class="fas fa-calendar-days solid-color" id="calendar"></i>
							<!-- <span class="primary-color filter-text">13/11 HAI</span> -->
							<DatePicker v-model="selectedDate" :value="selectedDate" @update:model-value="handleDateSelected" />
						</div>
						<i class="fas fa-chevron-right tertiary-color fw-bold cursor-pointer dp__pointer" id="next" @click="updateSelectedDate('next')"></i>
					</div>
				</div>
			</div>
		</div>


		<div class="table-data">
			<div v-if="displayedData && displayedData.length > 0">
				<template v-for="(league, leagueIndex) in displayedData" :key="leagueIndex">
					<table class="table table-borderless mt-3">
						<thead>
							<tr class="header align-middle">
								<th width="2%"><i class="fa-regular fa-star head"></i></th>
								<th width="10%">{{ $tr('league') }}</th>
								<th width="10%">{{ $tr('time') }}</th>
								<th width="20%">{{ $tr('home') }}</th>
								<th width="14%">{{ $tr('score') }}</th>
								<th width="20%">{{ $tr('visiting') }}</th>
								<th width="6%" class="text-center"><i class="flag"></i></th>
								<th width="6%" class="text-center">{{ $tr('ht') }}</th>
								<th class="text-center">{{ $tr('more') }}</th>
							</tr>
						</thead>
						<tbody>
							<tr v-for="(row, rowIndex) in league.matches" :key="rowIndex">
								<template v-if="row.homeRank">
									<td><i class="fa-regular fa-star data ps-2"></i></td>
									<td>{{ row.leagueShortName }}</td>
									<!-- <td>{{ formatUnixTime(row.matchTime) }}</td> -->
									<td>{{ formatTime(row.matchTime) }}</td>
									<td><span><img v-if="row.homeLogo" :src="storage_api_url + row.homeLogo" :alt="row.homeName" class="logo me-2" /></span>{{ row.homeName }}</td>
									<td class="text-danger">
										<template v-if="notStarted(row.status)">
											-
										</template>
										<template v-else>
											{{ row.homeScore }} - {{ row.awayScore }}
										</template>
										<!-- {{ row.homeScore }} - {{ row.awayScore }} -->
									</td>
									<td><span><img v-if="row.awayLogo" :src="storage_api_url + row.awayLogo" :alt="row.awayName" class="logo me-2" /></span>{{ row.awayName }}</td>
									<td class="text-center">
										{{ row.homeCorner ? row.homeCorner : '-' }} - {{ row.awayCorner ? row.awayCorner : '-' }}
									</td>
									<td class="text-center">
										{{ row.homeHalfScore }} - {{ row.awayHalfScore }}
									</td>
									<td class="text-center">
										<i class="tshirt"></i>
									</td>
								</template>
							</tr>
						</tbody>
					</table>
				</template>
			</div>
			<div v-else>
				<table class="table table-borderless mt-3">
					<thead>
						<tr class="header align-middle">
							<th width="2%"><i class="fa-regular fa-star head"></i></th>
							<th width="10%">{{ $tr('league') }}</th>
							<th width="10%">{{ $tr('time') }}</th>
							<th width="21%">{{ $tr('home') }}</th>
							<th width="14%">{{ $tr('score') }}</th>
							<th width="21%">{{ $tr('visiting') }}</th>
							<th width="6%" class="text-center"><i class="flag"></i></th>
							<th width="6%" class="text-center">{{ $tr('ht') }}</th>
							<th width="2%" class="text-center">{{ $tr('more') }}</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td colspan="9" class="text-center">--No Available Data--</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
		<a href="#" v-if="!showAll" @click.prevent="showMore" class="load-more text-center d-block">{{ $tr('load_more') }} <span><i class="fas fa-chevron-down ms-1"></i></span></a>
	</div>
</template>
  
<script>
	import DatePicker from '../components/DatePicker.vue';
	import { mapState, mapActions } from 'vuex';
	export default {
		name: "FixturesView",
		components: {
			DatePicker
		},

		data() {
			return {
				showAll: false,
				corners: [],
				matches: [],
				formattedDate: '',
				chunkSize: 5,
				startIndex: 0,
				selectedDate: new Date(),
				filteredMatches: [],
				storage_api_url: process.env.VUE_APP_ISPORTS_TEAM_STORAGE_URL
			};
		},
		methods: {
			...mapActions({
				fetchSchedules: 'schedules/fetchSchedules',
				updateSchedules: 'schedules/updateSchedules',
				fetchTeams: 'teams/fetchTeams',
			}),
			handleDateSelected(date) {
				this.selectedDate = date;
				this.filterAndMergeMatches();
			},
			updateSelectedDate(direction) {
				const newDate = new Date(this.selectedDate);

				if(direction == 'next') {
					newDate.setDate(newDate.getDate() + 1);
				} else if (direction == 'prev') {
					newDate.setDate(newDate.getDate() - 1);
				}

				this.selectedDate = newDate;
				this.filterAndMergeMatches();
			},
			async fetchData() {
				const today = new Date();

				this.formattedDate = this.getFormattedDate(today);
				// const day2 = this.getFormattedDate(new Date(today.getTime() + 1 * 24 * 60 * 60 * 1000));
				// const day3 = this.getFormattedDate(new Date(today.getTime() + 2 * 24 * 60 * 60 * 1000));
				// const day4 = this.getFormattedDate(new Date(today.getTime() + 3 * 24 * 60 * 60 * 1000));
				// const day5 = this.getFormattedDate(new Date(today.getTime() + 4 * 24 * 60 * 60 * 1000));
				// const day6 = this.getFormattedDate(new Date(today.getTime() + 5 * 24 * 60 * 60 * 1000));
				// const day7 = this.getFormattedDate(new Date(today.getTime() + 6 * 24 * 60 * 60 * 1000));
				// const day8 = this.getFormattedDate(new Date(today.getTime() + 7 * 24 * 60 * 60 * 1000));

				// try {
					// const todayResponse = await axiosInstance.get(`/sport/football/schedule?date=${this.formattedDate}`);
					// const day2Response = await axiosInstance.get(`/sport/football/schedule?date=${day2}`);
					// const day3Response = await axiosInstance.get(`/sport/football/schedule?date=${day3}`);
					// const day4Response = await axiosInstance.get(`/sport/football/schedule?date=${day4}`);
					// const day5Response = await axiosInstance.get(`/sport/football/schedule?date=${day5}`);
					// const day6Response = await axiosInstance.get(`/sport/football/schedule?date=${day6}`);
					// const day7Response = await axiosInstance.get(`/sport/football/schedule?date=${day7}`);
					// const day8Response = await axiosInstance.get(`/sport/football/schedule?date=${day8}`);

					// const schedulesResponse = await axiosInstance.get(`/sport/football/schedule?leagueId=1763`);
					// const schedulesResponse2 = await axiosInstance.get(`/sport/football/schedule?leagueId=14228`);

					// this.schedules = [
					// 	...todayResponse.data.data,
					// 	...day2Response.data.data,
					// 	...day3Response.data.data,
					// 	...day4Response.data.data,
					// 	...day5Response.data.data,
					// 	...day6Response.data.data,
					// 	...day7Response.data.data,
					// 	...day8Response.data.data
					// ];

					// this.schedules = schedulesResponse.data.data;
					// this.schedules = [...schedulesResponse.data.data, ...schedulesResponse2.data.data];

				await this.fetchSchedules();

				this.filterAndMergeMatches();

				
				await this.fetchTeams();

				this.mergeData(this.teams);

				// } catch (error) {
				// 	console.error('Error fetching data:', error);
				// }
			},
			mergeData(teamLogos) {
				const mergedSchedules = this.filteredMatches.reduce((acc, schedule) => {
					const leagueId = schedule.leagueId;
					
					if (!acc[leagueId]) {
						acc[leagueId] = {
							leagueName: schedule.leagueName,
							location: schedule.location,
							matches: []
						};
					}

					acc[leagueId].matches.push({
						...schedule,
						homeLogo: teamLogos[schedule.homeId] || '',
						awayLogo: teamLogos[schedule.awayId] || ''
					});

					return acc;
				}, {});

				this.matches = mergedSchedules;
			},
			formatUnixTime(unixTime) {
				const date = new Date(unixTime * 1000);
				const dd = String(date.getDate()).padStart(2, '0');
				const mm = String(date.getMonth() + 1).padStart(2, '0'); // January is 0!
				const yy = String(date.getFullYear()).slice(-2);
				const hh = String(date.getHours()).padStart(2, '0');
				const min = String(date.getMinutes()).padStart(2, '0');

				return `${dd}-${mm}-${yy} ${hh}:${min}`;
			},
			filterAndMergeMatches() {
				const startDate = new Date(this.selectedDate);
				const endDate = new Date(this.selectedDate);
				endDate.setDate(endDate.getDate() + 7);

				const today = new Date();
				const todayDateString = today.toISOString().split('T')[0];

				this.filteredMatches = this.schedules.filter(match => {
					const matchDate = new Date(match.matchTime * 1000);
					const matchDateString = matchDate.toISOString().split('T')[0];
					return (matchDate >= startDate && matchDate <= endDate) || matchDateString === todayDateString;
				});

				this.mergeData(this.teams);
			},

			getFormattedDate(date) {
				// Format the date as yyyy-MM-dd
				const year = date.getFullYear();
				const month = this.padZero(date.getMonth() + 1);
				const day = this.padZero(date.getDate());
				return `${year}-${month}-${day}`;
			},
			padZero(value) {
				return String(value).padStart(2, '0');
			},
			formatTime(timestamp) {
				let date = new Date(timestamp * 1000);
				let hours = date.getHours().toString().padStart(2, '0');
				let minutes = date.getMinutes().toString().padStart(2, '0');
				return hours + ':' + minutes;
			},
			// formatTime(timestamp) {
			// 	let currentDate = new Date();
			// 	let date = new Date(timestamp * 1000);

			// 	if (date > currentDate) {
			// 		let timeDiffHours = Math.floor((date - currentDate) / (1000 * 60 * 60));

			// 		currentDate.setHours(currentDate.getHours() + timeDiffHours);
			// 	}

			// 	let hours = currentDate.getHours().toString().padStart(2, '0');
			// 	let minutes = currentDate.getMinutes().toString().padStart(2, '0');

			// 	return hours + ':' + minutes;
			// },
			loadMoreData() {
				this.startIndex += this.chunkSize;
			},
			showMore() {
				this.chunkSize += 5;
				
				if (this.chunkSize >= Object.values(this.matches).length) {
					this.showAll = true;
				}
			},
			notStarted(status) {
				switch (status) {
					case 0:
						return true;
					default:
						return false;
				}
			},
		},
		created() {
			this.fetchData();
			// setInterval(this.updateSchedules, 21600000); // 6hours
			setInterval(() => {
				const now = new Date();
				const hours = now.getHours();
				const minutes = now.getMinutes();

				// If the time is exactly 12 AM or 12 PM
				if ((hours === 0 || hours === 12) && minutes === 0) {
				this.updateSchedules();
				}
			}, 60000); // Check every minute
		},
		mounted() {
			this.formattedDate = this.getFormattedDate(new Date());
		},
		computed: {
			...mapState({
				schedules: state => state.schedules.schedules,
				teams: state => state.teams.teams,
			}),
			displayedData() {
				const matchesArray = Object.values(this.matches);

				matchesArray.sort((a, b) => {
					const keyA = Object.keys(this.matches).find(key => this.matches[key] === a);
					const keyB = Object.keys(this.matches).find(key => this.matches[key] === b);
					
					// show first
					// EURO Cup
					if (keyA === '1763') return -1;
					if (keyB === '1763') return 1;

					// Copa America
					if (keyA === '14228') return -1;
					if (keyB === '14228') return 1;
					return 0;
				});

				return this.showAll ? matchesArray : matchesArray.slice(0, this.chunkSize);
			},

			shouldShowMoreButton() {
				return this.startIndex < this.matches.length;
			},

			tableChunks() {
				const chunkSize = 5;
				const chunks = [];
				for (let i = 0; i < this.displayedData.length; i += chunkSize) {
					chunks.push(this.displayedData.slice(i, i + chunkSize));
				}
				return chunks;
			}
		}
	}
</script>

<style scoped>
	.filter-type {
		gap: 4px;
	}
	.type {
		padding: 4px 8px;
		border-radius: 8px;
		background-color: #EEEEEE;
		border: 1px solid #EEEEEE;
		font-size: 14px;
		color: #8A8A8A;
		font-weight: 600;
		min-width: 62px;
	}
	.type:hover,
	.type:focus,
	.type.active {
		border: 1px solid #044CBF;
		background-color: #FFFFFF;
		color: #044CBF;
	}
	.filter-text {
		font-size: 14px;
		font-weight: 600;
	}

	.table thead th:first-child, .header th:first-child {
		border-top-left-radius: 10px;
	}
	.table thead th:last-child, .header th:last-child {
		border-top-right-radius: 10px;
	}
	.table thead th, .header th {
		background-color: #044CBF;
		color: #FFFFFF;
		border-bottom: 3px solid #FF644A;
		padding: 8px 16px;
		font-size: 13px;
		font-weight: 700;
	}
	.table td {
		color: #111111;
		font-size: 12px;
		font-weight: 400;
	}
	.table td.colored div {
		width: 85px;
		background-color: #EDEDED;
		padding: 4px;
		font-size: 11px;
		font-weight: 700;
		color: #000000;
	}
	.table td.colored:last-child div {
		width: 40px;
	}
	.table tbody .text-danger {
		color: #FF644A;
		font-size: 14px;
		font-weight: 700;
	}
	.fa-star.data {
		color: #C2C2C2;
	}
	.load-more {
		font-size: 12px;
		color: #958C8C;
	}
	.flag {
		background-image: url('../assets/img/flag.png');
		background-size: contain;
		background-repeat: no-repeat;
		width: 16px;
		height: 16px;
		display: block;
		margin: 0 auto;
	}
	.tshirt {
		background-image: url('../assets/img/tshirt.png');
		background-size: contain;
		background-repeat: no-repeat;
		width: 20px;
		height: 20px;
		display: block;
		margin: 0 auto;
	}
	.logo {
		width: 16px;
		height: 16px;
	}
</style>
  