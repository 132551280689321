<template>
	<section class="top-header">
		<div class="container">
			<div class="row">
				<div class="col-12 col-md-6">
					<!-- <div class="custom-shape primary-bg"></div> -->
					<img src="../assets/img/ole-logo.gif" alt="" class="logo">
				</div>
				<div class="col-12 col-md-6 align-self-center">
					<div class="user-settings d-flex gap-2 gap-md-4 align-items-center justify-content-end d-none">
						<div class="user-info d-flex gap-2 align-items-center order-3 order-md-1">
							<div class="user-shape"></div>
							<p class="m-0 fw-bold d-none d-md-block">User123</p>
						</div>
						<div class="icon order-1 order-md-2">
							<figure>
								<img src="../assets/img/star.png" alt="">
							</figure>
						</div>
						<div class="icon order-2 order-md-3">
							<figure>
								<img src="../assets/img/menu.png" alt="">
							</figure>
						</div>
					</div>
					<div class="user-settings d-flex gap-2 gap-md-4 align-items-center justify-content-center justify-content-md-end">
						<div class="dropdown">
							<button class="dropdown-toggle" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false">
								<img :src="selectedOption.img" alt="" class="flag-icon" />
							</button>
							<ul class="dropdown-menu" aria-labelledby="dropdownMenuButton">
								<li v-for="option in languageOptions" :key="option.value">
								<a class="dropdown-item" href="#" @click="selectLanguage(option)">
									<img :src="option.img" alt="" class="flag-icon" />
									{{ option.label }}
								</a>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
	<nav class="navbar navbar-expand">
		<div class="container">
			<!-- <a class="navbar-brand" href="#"></a> -->
			<button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
			<span class="navbar-toggler-icon"></span>
			</button>
			<div class="collapse navbar-collapse justify-content-center" id="navbarNav">
				<ul class="navbar-nav">
					<li class="nav-item">
						<router-link class="nav-link" to="/">{{ $tr('scores') }}</router-link>
					</li>
					<!-- <li class="nav-item">
						<router-link class="nav-link" to="/tables">{{ $tr('tables') }}</router-link>
					</li> -->
					<li class="nav-item">
						<router-link class="nav-link" to="/fixtures">{{ $tr('fixtures') }}</router-link>
					</li>
					<li class="nav-item">
						<router-link class="nav-link" to="/odds">{{ $tr('odds') }}</router-link>
					</li>
				</ul>
			</div>
		</div>
	</nav>
</template>
  
<script>
	import { selectedLanguage } from '../lang/i18n.js';
	export default {
		name: 'AppHeader',
		data() {
			return {
				appName: process.env.VUE_APP_APP_NAME,

				languageOptions: [
					{ value: 'en', label: 'English', img: require('../assets/img/english.png') },
					{ value: 'vi', label: 'Tiếng Việt', img: require('../assets/img/vietnam.png') }
				]
			}
		},
		computed: {
			selectedOption() {
				return this.languageOptions.find(option => option.value === selectedLanguage.value);
			}
		},
		methods: {
			selectLanguage(option) {
				selectedLanguage.value = option.value;
			}
		}
	};
</script>
  
<style>
.logo {
	width: 100%;
}
.flag-icon {
	width: 20px;
}
.dropdown button {
	background: transparent;
	border: none;
	color: #FFFFFF;
}
.dropdown {
	border-radius: 10px;
	box-shadow: none;
	border: none;
	padding: 1px 6px;
	margin-top: 1em;
}
.dropdown-toggle::after {
	margin-left: .75em;
}

@media (min-width: 576px) {
	.logo {
		width: auto;
	}
}

@media (min-width: 768px) {
	.dropdown {
		box-shadow: 0px 2px 2px 0px #0000001A;
		border: 1px solid #E2EDFF;
		padding: 4px 8px;
		margin-top: 0;
	}
}
</style>
  