/* eslint-disable */
import axios from 'axios';

const state = {
	teams: {},
	loaded: false,
};

const mutations = {
	setTeams(state, teams) {
	  	state.teams = teams;
	},
	setTeamsLoaded(state, loaded) {
	  	state.loaded = loaded;
	},
};

const actions = {
	async fetchTeams({ commit, state }) {
		if (state.loaded) return;
		try {
			const response = await axios.get(`${process.env.VUE_APP_ISPORTS_TEAM_API_URL}teams`);
			const teams = response.data;
			const teamLogos = teams.reduce((acc, team) => {
				acc[team.team_id] = team.logo;
				return acc;
			}, {});

			commit('setTeams', teamLogos);
			commit('setTeamsLoaded', true);
		} catch (error) {
			console.error('Error fetching teams:', error);
		}
	},
};

const getters = {
	getTeams(state) {
	  	return state.teams;
	},
};

export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters,
};
