import { ref } from 'vue';
import { translations } from './translations.js';

export const selectedLanguage = ref('en');

export const __ = (key) => {
    return translations[selectedLanguage.value][key] || key;
};

export const setupI18n = (app) => {
    app.config.globalProperties.$tr = __;
};