import axiosInstance from '../../axios';

const state = {
	matches: [],
	updatedMatches: [],
	matchesLoaded: false,
	updatingMatches: false,
};

const mutations = {
	setMatches(state, matches) {
		state.matches = matches;
	},
	setMatchesLoaded(state, loaded) {
		state.matchesLoaded = loaded;
	},
	setUpdatedMatches(state, matches) {
		state.updatedMatches = matches;
	},
	setUpdatingMatches(state, updating) {
		state.updatingMatches = updating;
	}
};

const actions = {
	async fetchMatches({ commit, state }) {
		if (state.matchesLoaded) return;
		try {
			const response = await axiosInstance.get('/sport/football/livescores');

			commit('setMatches', response.data.data);
			commit('setMatchesLoaded', true);
		} catch (error) {
			console.error('Error fetching matches:', error);
		}
	},
	async updateMatches({ commit }) {
		if (state.updatingMatches) return;
		commit('setUpdatingMatches', true);
		try {
			const response = await axiosInstance.get('/sport/football/livescores/changes');

			commit('setUpdatedMatches', response.data.data);
		} catch (error) {
			console.error('Error updating matches:', error);
		} finally {
			setTimeout(() => {
				commit('setUpdatingMatches', false);
			}, 30000);
		}
	}
};

const getters = {
	getMatches(state) {
		return state.matches;
	},
	getUpdatedMatches(state) {
		return state.updatedMatches;
	}
};

export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters
};
