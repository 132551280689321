<template>
	<footer class="bg-light pt-5 mt-4">
		<div class="container">
			<div class="row">
				<div class="col-md-4">
					<div class="about">
						<h5 class="">{{ $tr('about') }}</h5>
						<p class="text-capitalize">
							{{ $tr('about_content') }}
						</p>
					</div>
					<div class="providers mt-5">
						<h5 class="">{{ $tr('providers') }}</h5>
						<div class="">
							<img class="mb-2 me-4" src="../assets/img/footer/ag.png" />
							<img class="mb-2 me-4" src="../assets/img/footer/bbin.png" />
							<img class="mb-2 me-4" src="../assets/img/footer/dg.png" />
							<img class="mb-2 me-4" src="../assets/img/footer/eg.png" /> <br/>
							<img class="mb-2 me-4" src="../assets/img/footer/ebet.png" />
							<img class="mb-2 me-4" src="../assets/img/footer/sexy.png" />
							<img class="mb-2 me-4" src="../assets/img/footer/w.png" />
							<img class="mb-2 me-4" src="../assets/img/footer/logo-ole-sports.png" /> <br/>
							<img class="mb-2 me-4" src="../assets/img/footer/cmd368.png" />
							<img class="mb-2 me-4" src="../assets/img/footer/pinnacle.png" />
							<img class="mb-2 me-4" src="../assets/img/footer/sabasports.png" />
							<img class="mb-2 me-4" src="../assets/img/footer/esports.png" /> <br/>
							<img class="mb-2 me-4" src="../assets/img/footer/saba.png" />
							<img class="mb-2 me-4" src="../assets/img/footer/tpg.png" />
							<img class="mb-2 me-4" src="../assets/img/footer/evo.png" />
							<img class="mb-2 me-4" src="../assets/img/footer/evo_p.png" />
						</div>
					</div>
				</div>
				<div class="col-md-5">
					<div class="d-flex gap-5 justify-content-start justify-content-md-center mt-4 mt-md-0">
						<div class="games">
							<h5 class="">{{ $tr('games') }}</h5>
							<ul class="list-unstyled text-capitalize">
								<li><a href="#">{{ $tr('sport') }}</a></li>
								<li><a href="#">{{ $tr('e_sport') }}</a></li>
								<li><a href="#">{{ $tr('casino') }}</a></li>
								<li><a href="#">{{ $tr('slot') }}</a></li>
								<li><a href="#">{{ $tr('fufish') }}</a></li>
								<li><a href="#">{{ $tr('lottery') }}</a></li>
								<li><a href="#">{{ $tr('card_game') }}</a></li>
								<li><a href="#">{{ $tr('horse_race') }}</a></li>
							</ul>
						</div>
						<div class="information">
							<h5 class="">{{ $tr('information') }}</h5>
							<ul class="list-unstyled text-capitalize">
								<li><a href="#">{{ $tr('about_us') }}</a></li>
								<li><a href="#">{{ $tr('menu_play_responsibly') }}</a></li>
								<li><a href="#">{{ $tr('privacy_policy') }}</a></li>
								<li><a href="#">{{ $tr('terms_conditions') }}</a></li>
								<li><a href="#">{{ $tr('affiliates') }}</a></li>
								<li><a href="#">{{ $tr('blog') }}</a></li>
								<li><a href="#">{{ $tr('contact_us') }}</a></li>
							</ul>
						</div>
					</div>
				</div>
				<div class="col-md-3 text-md-end">
					<!-- <div class="mb-2">
						<a href="#"><img src="path/to/vietnam_flag.png" alt="Vietnam Flag" class="img-fluid" style="max-width: 30px;"></a>
						Việt Nam
					</div> -->
					<div class="d-flex justify-content-start justify-content-md-end mt-4 mt-md-0">
						<div class="text-start logos">
							<h5 class="">{{ $tr('license') }}</h5>
							<div class="d-flex gap-4">
								<img src="../assets/img/footer/logo-chelsea-1.png" alt="License" class="img-fluid" style="max-width: 50px;">
								<img src="../assets/img/footer/logo-chelsea-2.png" alt="License" class="img-fluid" style="max-width: 50px;">
							</div>
						</div>
					</div>
					<div class="d-flex justify-content-start justify-content-md-end mt-4 mt-md-5">
						<div class="text-start logos">
							<h5 class="">{{ $tr('play_responsibly') }}</h5>
							<div class="d-flex gap-4">
								<img src="../assets/img/footer/18plus.png" alt="18+" class="img-fluid" style="max-width: 50px;">
								<img src="../assets/img/footer/gamecare.png" alt="GameCare" class="img-fluid" style="max-width: 50px;">
								<img src="../assets/img/footer/bga.png" alt="Be Gamble Aware" class="img-fluid" style="max-width: 50px;">
							</div>
						</div>
					</div>
				</div>
			</div>
			<hr>
			<div class="text-center pb-4 pt-3">
				<p class="m-0">{{ $tr('copyright') }}</p>
			</div>
		</div>
	</footer>
</template>
  
<script>
	export default {
		name: 'AppFooter',
		data() {
			return {
			};
		}
	};
</script>
  
<style scoped>
	footer {
		background-color: #f8f9fa;
	}
	footer h5 {
		margin-bottom: 1rem;
		font-weight: 400;
		font-size: 18px;
		font-weight: 600;
	}
	footer p {
		color: #989898;
	}
	footer a {
		text-decoration: none;
		color: #989898;
		font-weight: 400;
		font-size: 14px;
		margin-bottom: 8px;
	}
	footer a:hover {
		color: #212529;
	}
	.logos {
		min-width: 176px;
	}
	.games ul li,
	.information ul li {
		margin-bottom: 10px;
	}
	.providers img {
		
	}
</style>
  